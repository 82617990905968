const ELIGIBLE_COUNTRIES_FOR_HOLIDAYS = [
    'United States',
    'USA',
    'Canada',
    'Australia',
    'New Zealand',
    'United Kingdom',
    'Ireland',
];

export const isGeoEligibleForSeasonal = (country: string | undefined) => {
    return country && ELIGIBLE_COUNTRIES_FOR_HOLIDAYS.includes(country);
};
